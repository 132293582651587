import {configureStore, createStore , applyMiddleware , compose } from "@reduxjs/toolkit";
import { reducers } from "../reducers/index"
import thunkMiddleware from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose ; 

// export const store = configureStore({
//     reducer: {
//         reducers: reducers,
//     },
//   });
// const store = createStore(reducers, applyMiddleware(thunk));

const store = createStore(
    reducers, composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
